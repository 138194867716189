<template>
    <div>
        <header-portrate v-if="$store.state.licenseType.HideHeader"  />
        
        <div class="m-2 mb-0">
            <div style="background:lightblue;color:#000;padding:5px;text-align:center;cursor:pointer" @click="goHome()">
                {{ lang.home }}
            </div>
            <v-row class="m-2" style="border:1px solid #000;border-bottom:none !important;background:#fff">
                
                <v-col cols="12" md="8" sm="12" class="_mxmnheight_" style="background:rgb(200, 218, 224);border-left:1px solid #ccc;border-bottom:1px solid #000;padding:0px;border-top:1px solid #ccc !important;position:relative">
                    <div style="display:flex;justify-content:center;align-items:center;background:#fff;border:1px solid #ccc;border-top:none !important;">
                        <!-- <div style="justify-items:center;font-size:3rem;width:80px;display:flex;flex-direction:column;justify-content:center;align-items:center;">
                            <img :src="`/img/menu.png`" v-b-modal.extra_buttons style="width:32px;cursor:pointer;" />
                            <img :src="`/img/logout-rounded.png`" style="width:32px;cursor:pointer;margin-top:5px;" @click="$router.push({path:'/logout'})" />
                           
                        </div> -->
                    
                        <div classs="displayFlexNo text-center" style="width:100%;height:128px;overflow:auto;background:#eee;padding:10px">
                            <b-button class="wp-100 backBlack mt-1 catBTN" style="font-weight:600;width:17% !important;margin-inline-end:2%;background:#e67a00 !important;border-radius: 10px !important;color:#fff !important;" v-for="(item,index) in categories" :key="index" :id="`cats_`+index" @click="changeColor(index), getCats(item)" >{{item.name}} </b-button>
                        </div>
                    </div>
                    <div class="scrollMe">
                        <div>
                            <div @click="scropTop" style="padding:0;border:none;box-shadow:none;font-size:.9rem;margin-top:10px;cursor:pointer;">
                                <img :src="`img/arrow-up-4.png`"  style="width:48px;"/>
                            </div>
                        </div>
                        <div @click="scropDown" style="padding:0;border:none;box-shadow:none;font-size:.9rem;margin-top:10px;cursor:pointer;">
                            <img :src="`img/arrow-down-3.png`"  style="width:48px;"/>
                        </div>
                    </div>
                    <v-row class=" m-3" id="itemArea" style="height:calc(100vh - 290px) !important;padding-bottom:40px;overflow:hidden;background:rgb(200, 218, 224)">
                        <b-button class="btn-sm btn btn-primary wp-150" style="color:#fff !important;"
                            v-for="(item,index) in subcats" @click="getCats(item)" :key="index">{{item.name}}</b-button>
                        <b-button class="btn-sm btn btn-wght wp-150" style="height:130px !important;font-weight:bolder;background:#fff !important;color:#000 !important;margin-bottom: 10px !important;"
                            v-for="(item,index) in allitems" @click="addItem(item,true)" :key="index">
                                <div class="rowPrice" style="background:#e67a00 !important;color:#fff !important;">{{lang.price}} {{$RoundNum(parseFloat(item.ftotal)/parseFloat(item.qty),2)}} {{lang.curency}}</div>
                                <div class="rrowPrice">{{lang.remainpso}} <span style="padding:2px 10px;background:#000;color:#fff">{{item.lqty}}</span></div>
                            {{item.name}}</b-button>
                        
                    </v-row>
                    <div id="countbtns" style="display:none;">
                        <b-button class="btn-sm btn btn-success qtyBTN" @click="addOne()">+</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(1)">1</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(5)">5</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(10)">10</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(15)">15</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(20)">20</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(25)">25</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(30)">30</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(50)">50</b-button>
                        <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(100)">100</b-button>
                        <b-button class="btn-sm btn backBlue qtyBTN" @click="minOne()">-</b-button>
                        <b-button class="btn-sm btn backRed qtyBTN" @click="delSel()">{{lang.delete}}</b-button>
                    </div>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="_mxmnheight_" style="background:#fffee1;border-bottom:1px solid #000;padding-top:0px;background:#e9e9e9;border-top:1px solid #ccc !important;">
                    <v-row style="border-bottom:1px solid #ccc;padding-bottom: 12px;">
                        <v-col cols="12" md="6">
                            <form ref="searchByCode">
                                    <div style="display:flex;justify-content:space-between;justify-items: center;align-items: flex-end">
                                        <div style="width:159px"><label>{{ lang.item_code }}</label>
                                        <b-form-input class="inborder"
                                            :label="lang.item_code"
                                            v-model="itemcode"
                                            @change="getItem()"
                                            @focus="itemcode = ''"
                                            ref="itemcodevalue"
                                            autocomplete="off"
                                            id="titemcode"
                                            ></b-form-input></div>
                                            <div style="padding:5px 10px;background:green;cursor:pointer;color:#fff;">{{lang.search}}</div>
                                            <!-- <b-button class="btn-sm btn btn-success" id="additmbtn" @click="getItem()">{{lang.add}}</b-button> -->
                                    </div>
                                    <div style="display:flex;justify-content:space-between;justify-items: center;align-items: flex-end">
                                        
                                        <div style="width:159px"><label>{{ lang.search_by_name }}</label>
                                        <b-form-input class="inborder"
                                            :label="lang.search_by_name"
                                            v-model="itemName"
                                            @change="getItemByNames()"
                                            @focus="itemName = ''"
                                            ref="nameValue"
                                            autocomplete="off"
                                            id="titemcode"
                                            ></b-form-input>
                                        </div>
                                            <div style="padding:5px 10px;background:green;cursor:pointer;color:#fff;">{{lang.search}}</div>
                                    </div>

                                </form>
                        </v-col>
                        <v-col cols="12" md="6" >
                                <div>
                                    <div style="display:flex;justify-content:space-between;justify-items: center;align-items: flex-end">
                                        <div style="width:159px"><label>{{ lang.mobile }}</label>
                                    <b-form-input class="inborder"
                                    :label="lang.mobile"
                                    v-model="customer.mobile"
                                    @change="getName()"
                                    /></div>
                                    <div style="padding:5px 10px;background:green;cursor:pointer;color:#fff;">{{lang.search}}</div>
                                    </div>
                                </div>
                                <div>
                                    <label>{{ lang.full_name }}</label>
                                    <b-form-input class="inborder"
                                    id="tfullname"
                                    :label="lang.full_name"
                                    v-model="customer.full_name"
                                    />
                                </div>
                        </v-col>
                    </v-row>
                    
                    <div class="_invoicetables_" style="background:#FFF;border-right:1px solid #000;min-height:270px !important;max-height:270px !important;margin-top:15px;border:1px solid #000;">
                        <v-simple-table id="billtable">
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="border-inline-start:1px solid #000 !important">{{lang.description}}</th>
                                    <th class="text-center backBlack" style="width:15%">{{lang.qtty}}</th>
                                    <th class="text-center backBlack" style="width:15%">{{lang.total}}</th>
                                    <th class="text-center backBlack" style="width:15%">{{lang.vat}}</th>
                                    <th class="text-center backBlack" style="width:15%;border-inline-end:1px solid #000 !important">{{lang.total}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(citem,index) in allItemCart" :key="index" :id="`item_`+index" @click="selectThie(citem,index)" style="height: 30px;">
                                    <td class="itemrowstd" style="white-space:pre-wrap;height:20px !important">{{citem.name}}</td>
                                    <td class="itemrowstd" style="width:100px;text-align:center;height:20px !important">{{citem.qty}}</td>
                                    <td class="itemrowstd" style="width:100px;text-align:center;height:20px !important">{{citem.total}}</td>
                                    <td class="itemrowstd" style="width:100px;text-align:center;height:20px !important">{{citem.vat}}</td>
                                    <td class="itemrowstd" style="width:100px;text-align:center;height:20px !important;background:lightgreen">{{citem.ftotal}}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <v-row>
                        <v-col cols="12">
                            <div class="m-1 text-center">
                                <b-button class="btn-sm btn backBlack qtyBTN" style="width:80px;" @click="openIt(1)">تعدل الكمية</b-button>
                                <b-button class="btn-sm btn btn-success qtyBTN" @click="addOne()">+</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(1)">1</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(5)">5</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(10)">10</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(15)">15</b-button>
                                <!-- <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(20)">20</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(25)">25</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(30)">30</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(50)">50</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" @click="addThis(100)">100</b-button> -->
                                <b-button class="btn-sm btn backBlue qtyBTN" @click="minOne()">-</b-button>
                                <b-button class="btn-sm btn backRed qtyBTN" @click="delSel()">{{lang.delete}}</b-button>
                                <b-button class="btn-sm btn backBlack qtyBTN" style="width:80px;" @click="openIt(2)">تعدل السعر</b-button>
                            </div>
                            <v-card
                            class="mx-auto"
                            outlined
                            style="border:1px solid #000 !important;background:#ccc">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <b-button class="btn-sm btn backGreen" @click="updatePaidAmount()" v-b-modal.modalPOSAddInvoice>{{lang.add}}</b-button>
                                        <b-button class="btn-sm btn backBlack" @click="$refs.discountMe.checkDiscoutn()" v-b-modal.modal-prevent-closing>{{lang.discount}}</b-button>
                                        <b-button class="btn-sm btn backBlack" v-b-modal.modalPOSAddNote>{{lang.notes}}</b-button>
                                        <b-button class="btn-sm btn backRed" @click="cancelOrder()">{{lang.cancel}}</b-button>
                                    </v-list-item-content>
                                    <v-list-item-content style="margin-inline-start:10px">
                                        <v-list-item-subtitle class="backBlack p-2" style="background:#fff !important;display:flex;justify-content:space-between;color:#fff;border-radius:5px;">
                                            <div>{{lang.total}}</div>  
                                            <div>{{intotal}} </div>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="backBlack p-2" style="background:#fff !important;display:flex;justify-content:space-between;color:#fff;border-radius:5px;">
                                            <div style="font-size:0.7rem">{{lang.total_after_discountt}}</div>  
                                            <div>{{total_after_discount}} </div>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="backBlack p-2" style="background:#fff !important;display:flex;justify-content:space-between;color:#fff;border-radius:5px;">
                                            <div>{{lang.vat}}</div>  
                                            <div>{{invat}}</div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar
                                        tile
                                        size="120"
                                        :color="`#000`"
                                        style="border:1px solid #000;display:flex;flex-direction:column;"
                                    >
                                    <div style="color:#fff">{{ lang.fftotal }}</div>
                                    <div style="font-size:1.5rem;color:#fff">{{ inftotal }}</div>
                                    
                                    </v-list-item-avatar>
                                </v-list-item>
                                <v-card-actions>
                                    
                                </v-card-actions>
                            </v-card>
                            <div class="mt-2" style="text-align:center;border:1px solid #000;background:#fff;padding:5px;display:none;"><img :src="`img/samcotec-logo.png`" style="width:120px;" /></div>
                        </v-col>
                    </v-row>
                    <div class="moneyTables" style="display:none;">
                        <v-simple-table style="margin-bottom:10px;margin-left:11px;margin-right:6px;margin-top:15px;">
                            <thead>
                                <tr>
                                    <th class="w100 backBlack">{{lang.total}}</th>
                                    <td>{{intotal}}</td>
                                    <th class="w100 backBlue">{{lang.total_after_discountt}}</th>
                                    <td>{{total_after_discount}}</td>
                                </tr>
                                <tr>
                                    <th class="w100 backRed">{{lang.vat}} 15%</th>
                                    <td>{{invat}}</td>
                                    <th class="w100 backGreen">{{lang.fftotal}}</th>
                                    <td>{{inftotal}}</td>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <b-button class="btn-sm btn btn-info backBlue actBTN" @click="$refs.discountMe.checkDiscoutn()" v-b-modal.modal-prevent-closing>{{lang.discount}}</b-button>
                        <b-button class="btn-sm btn btn-warning backBlack actBTN" v-b-modal.modalPOSAddNote>{{lang.invoice_notes}}</b-button>
                
                        <b-button class="btn-sm btn btn-success backGreen actBTN" @click="updatePaidAmount()" v-b-modal.modalPOSAddInvoice>{{lang.add}}</b-button>
                        <b-button class="btn-sm btn btn-danger backRed actBTN" @click="cancelOrder()">{{lang.cancel}}</b-button>
                        
                    </div>
                </v-col>
                <div class="floatingButtom" style="display:none;" v-b-toggle.extra_buttons>الوصول السريع</div>
            </v-row>
        </div>
        <div style="display:flex;flex-wrap: nowrap;overflow: auto;">
            <posDiscount ref="discountMe" />
            <posNotes />
            <pos-addinvoice ref="addInvoice2" />
            <posAddModalInvoice ref="addInvoice" />
            <pos_Discount />
            <vue-snotify></vue-snotify>
            <posShifts v-if="showMessage" />
            <posExtraButtons ref="cashBTN" />
            <posExtraButtonsMngmnt ref="manBTN" />
        </div>

        <div class="editPrice" id="editPrice">
            <div class="title">تعديل السعر</div>
            <div class="body">
                <label>اجمالي السعر مع الضريبة</label>
                <b-form-input class="inborder" v-model="newprice" />
            </div>
            <div class="buttons">
                <b-button class="updateButton" @click="addPrice()">{{lang.update}} {{ lang.price }}</b-button>
                <b-button class="cancelButton" @click="cancelPrice()">{{lang.cancel}}</b-button>
            </div>
        </div>
        <div class="editPrice" id="editQty">
            <div class="title">تعديل الكمية</div>
            <div class="body">
                <label>اجمالي الكمية</label>
                <b-form-input class="inborder" v-model="newQty" />
            </div>
            <div class="buttons">
                <b-button class="updateButton" @click="addQty()">{{lang.update}} {{ lang.qty }}</b-button>
                <b-button class="cancelButton" @click="cancelQty()">{{lang.cancel}}</b-button>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import posDiscount from '@/components/pos_discount.vue';
import posNotes from '@/components/posNotes.vue';
import PosAddinvoice from '@/components/pos-addinvoice.vue';
import posAddModalInvoice from '@/components/posAddModalInvoice.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import pos_Discount from '@/components/posDiscount.vue'
import posShifts from '@/components/pos-shifts.vue'
import posExtraButtons from '@/components/pos-extra-buttons.vue'
import posExtraButtonsMngmnt from '@/components/pos-extra-buttons-management.vue'
import {SnotifyPosition} from 'vue-snotify';
export default{
  components: { posExtraButtonsMngmnt,posExtraButtons,posShifts,posDiscount, posNotes, PosAddinvoice,HeaderPortrate, pos_Discount,posAddModalInvoice},
    name: 'POS',
    data() {
        return {
            newprice: 0,
            newQty: 1,
            newqty: 0,
            discount_type: 1,
            showMessage: false,
            shiftOpen: true,
            itemName: '',
            itemcode: '',
            selectedIndex: -1,
            selectedItem: {},
            categories: [],
            subcats:[],
            itemrows: [],
            allitems: [],
            customer:{
                id: '',
                mobile: '',
                full_name: '',
                customerid: '',
            },
            company:{
                fullname: '',
                vatid: '',
            },
            discount: 0,
            notes: '',
            paytype: 1,
            invtype: 1,
            paytypes: [],
            paidamount: 0,
            cash:0,
            span:0,
            currentPos:0
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        invtypes: function(){
            return [
                {
                    text: this.lang.none_paid_invoice,
                    value: 2,
                },
                {
                    text: this.lang.paid_invoice,
                    value: 1,
                },
            ]
        },
        curency: function(){
            return this.lang.curency;
        },
        total_after_discount: function(){
            if(this.discount == '') return this.intotal;
            let t = 0;
            if(this.intotal == 0) return t;
            t = parseFloat(this.intotal) - parseFloat(this.discount);
            return this.$RoundNums(t,1);
        },
        intotal: function(){
            let t = 0;
            for(let i=0;i<this.allItemCart.length;i++){
                t = parseFloat(t) + parseFloat(this.allItemCart[i].total);
            }
            return this.$RoundNums(t,1);
        },
        invat: function(){
            let t = 0;
            // const dvat = parseFloat(this.$store.state.settings.def_vat) / parseFloat(100);
            let dvat = parseFloat(15) / parseFloat(100);
            for(let i=0;i<this.allItemCart.length;i++){
                if(this.allItemCart[i].vat == 0) dvat = 0;
                 t = parseFloat(t) + parseFloat(this.allItemCart[i].vat);
            }
            
            t = parseFloat(this.total_after_discount) * parseFloat(dvat)

            return this.$RoundNums(t,1);
        },
        inftotal: function(){
            let t = 0;
            // for(let i=0;i<this.itemrows.length;i++){
            //     t = parseFloat(t) + parseFloat(this.itemrows[i].ftotal);
            // }
            // t = parseFloat(t) * parseFloat(this.discountper)
            t = this.$RoundNums(parseFloat(this.total_after_discount) + this.invat , 2);
            const per = parseFloat(t) - parseInt(t);
            if(per != 0 && per < 0.10) {
                t = parseInt(t)
            }
            if(per != 0 && per > 0.89) {
                t = parseInt(t) + 1
            }
            return t;
        },
        discountper: function() {
            let t = 0;
            if(this.intotal == 0) return t;
            t = parseFloat(this.discount) / parseFloat(this.intotal);
            
            return this.$RoundNums(t,1);
        },
        allItemCart: function(){
            let t=[];
            let q = {};
            for(let i=0;i<this.itemrows.length;i++){
                q = this.itemrows[i];
                const tot = this.$calcVat(q.ftotal, 3);
                // q.svat = tot.vat / q.qty
                let qty = 0
                let total = 0
                let vat = 0
                let ftotal = 0
                 qty = q.qty;
                 
                 total = this.$RoundNums(parseFloat(qty) * parseFloat(q.item_price),2);
                 vat = this.$RoundNums(parseFloat(qty) * parseFloat(q.svat),2);
                //  vat = q.vat;
                
                
                 ftotal = this.$RoundNums(parseFloat(total) + parseFloat(vat),2);
                 const per = parseFloat(ftotal) - parseInt(ftotal);
                 if(per != 0 && per < 0.10) {
                    ftotal = parseInt(ftotal)
                 }
                 if(per != 0 && per > 0.89) {
                   ftotal = parseInt(ftotal) + 1
                 }
                //  alert([total, vat, ftotal, q.item_price, q.svat])
                q.vat = vat;
                q.total = total;
                // CountQueuingStrategy.vat = vat;
                q.ftotal = ftotal;
                t.push(q)
            }
            return t;
        }
    },
    methods: {
        addQty()
        {
            this.itemrows[this.selectedIndex].qty = this.newQty;
            document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
            this.selectedIndex = -1;
            this.selectedItem = {};
            document.getElementById('editPrice').style.display = 'none';
        },
        addPrice()
        {
            let price = this.newprice;
            price = this.$RoundNums(parseFloat(price) / parseFloat(this.itemrows[this.selectedIndex].qty), 1)
            const tot = this.$calcVat(price, 3);
            this.itemrows[this.selectedIndex].item_price = this.$RoundNums(tot.tot, 2)
            this.itemrows[this.selectedIndex].total = this.$RoundNums(parseFloat(tot.tot) * parseFloat(this.itemrows[this.selectedIndex].qty), 2)
            this.itemrows[this.selectedIndex].vat = this.$RoundNums(parseFloat(tot.vat) * parseFloat(this.itemrows[this.selectedIndex].qty), 2)
            this.itemrows[this.selectedIndex].ftotal = this.$RoundNums(this.itemrows[this.selectedIndex].vat + this.itemrows[this.selectedIndex].total, 2)
            this.itemrows[this.selectedIndex].svat = parseFloat(tot.vat);
            // this.allItemCart[this.selectedIndex].item_price = this.$RoundNums(price, 2)
            // const tot = this.$calcVat(price, 3);
            
            // this.allItemCart[this.selectedIndex].item_price = this.$RoundNums(parseFloat(tot.tot) , 1)
            // this.allItemCart[this.selectedIndex].total = this.$RoundNums(parseFloat(tot.tot) * parseFloat(this.allItemCart[this.selectedIndex].qty), 1)
            // alert(tot.tot);
            // this.allItemCart[this.selectedIndex].item_price = this.$RoundNums(parseFloat(tot.tot) , 2)
            // this.allItemCart[this.selectedIndex].total = this.$RoundNums(parseFloat(tot.tot) * parseFloat(this.allItemCart[this.selectedIndex].qty), 2)
            // this.allItemCart[this.selectedIndex].vat = this.$RoundNums(parseFloat(tot.vat) * parseFloat(this.allItemCart[this.selectedIndex].qty), 2)
            // this.allItemCart[this.selectedIndex].ftotal = this.$RoundNums((this.$RoundNums(parseFloat(tot.tot) * parseFloat(this.allItemCart[this.selectedIndex].qty), 2)) + parseFloat(tot.vat), 2)
            
            document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
            this.selectedIndex = -1;
            this.selectedItem = {};
            document.getElementById('editPrice').style.display = 'none';
            // this.allItemCart[this.selectedIndex].ftotal = this.newprice;
            
            this.newprice = 0
        },
        cancelPrice()
        {
            document.getElementById('editPrice').style.display = 'none';
            this.newprice = 0
        },
        cancelQty()
        {
            document.getElementById('editQty').style.display = 'none';
            this.newQty = 1
        },
        openIt(id){
            if(this.selectedIndex != -1){
                if(id == 2){
                    document.getElementById('editPrice').style.display = 'block';
                    this.newprice = this.allItemCart[this.selectedIndex].ftotal
                }else if(id == 1){
                    document.getElementById('editQty').style.display = 'block';
                    this.newQty = this.allItemCart[this.selectedIndex].qty
                }
            }
        },
        goHome()
        {
            localStorage.setItem('currentMenu', 88);
            this.$router.push({path: '/clientList'});
        },
        openModal(id){
            if(id == 1){
                this.$refs['manBTN'].$refs['extra_buttons_management'].hide()
            }else{
                this.$refs['cashBTN'].$refs['extra_buttons_cashair'].hide()
            }
        },
        openShift(){
            const post = new FormData();
            post.append("type", "openSheft");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                // console.log(response.data);
                if(response.data.error.number == 101){
                    this.errorMessage = "هناك وردية اخرى قيد العمل الان";
                }
                else{
                    this.showMessage = false;
                }
                
            })
        },
        clientRep(){
            this.$refs.clientPaymentHome.mobile = this.customer.mobile;
            this.$refs.clientPaymentHome.getClient();
        }, 
        posExpensesHome(){
            this.$refs.posExpensesHome.reset();
        },
        posPurchaseHome(){
            
            this.$refs.posPurchaseHome.reset()
        },
        scropTop(){
            const elm = document.getElementById('itemArea');
            this.currentPos = parseInt(this.currentPos) - parseInt(50)
            if(this.currentPos < 0) this.currentPos = 0;
            elm.scroll(0,this.currentPos)
        },
        scropDown(){
            const elm = document.getElementById('itemArea');
            this.currentPos = parseInt(this.currentPos) + parseInt(50)
            elm.scroll(0,this.currentPos)
        },
        goBack(){
            this.$router.push({path: '/invoicelist'})
        },
        changeColor(index){
            for(let i=0;i<this.categories.length;i++){
                if(index == i){
                    document.getElementById('cats_'+i).classList.add("btnorange");
                    document.getElementById('cats_'+i).classList.remove("backBlack");
                }else{
                    document.getElementById('cats_'+i).classList.add("backBlack");
                    document.getElementById('cats_'+i).classList.remove("btnorange");
                }
            }
        },
        getItems(){
            document.getElementById('additmbtn').click();
        },
        getItem(){
            const post = new FormData();
            post.append('type','getCatProducts');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.itemcode);
            post.append('data[id]',0);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                if(typeof res.results.data[0] !== 'undefined')
                    this.addItem(res.results.data[0],false);
                
                document.getElementById('titemcode').value = '';
                this.itemcode = '';
                document.getElementById('titemcode').focus();
                document.getElementsByTagName('body')[0].click();
                this.$refs.searchByCode.reset();
                // this.$refs.itemcodevalue.reset();
            })
        },
        async getItemByNames(){
            const itemn = this.itemName;
            const post = new FormData();
            post.append('type','getProdByName');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemname]',itemn);
            post.append('data[id]',0);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                // if(typeof res.results.data[0] !== 'undefined')
                //     this.addItem(res.results.data[0],false);
                
                // document.getElementById('titemcode').value = '';
                // this.itemcode = '';
                // document.getElementById('titemcode').focus();
                // document.getElementsByTagName('body')[0].click();
                // this.$refs.searchByCode.reset();
                // this.$refs.itemcodevalue.reset();
                this.allitems = res.results.data;
            })
        },
        async getItemByName(){
            const itemn = this.itemName;
            if(itemn == '') return false;
            const resAr = JSON.parse(localStorage.getItem('allProducts'));
            if(typeof resAr === 'undefined'){
                await this.getAllItems();
            }
            const res = resAr.data;
            this.allitems = [];
            // // console.log(res.length);
            for(let i=0;i<res.length;i++){
                // // console.log(res[i].name.search(itemn));
                if(res[i].name.search(itemn) != -1){
                    res[i].qty = 1;
                    this.allitems.push(res[i]);
                }
            }
        },
        async getAllItems(){
            const post = new FormData();
            post.append('type','getProducts');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]',1);

            const response = await axios.post(
                this.$SAMCOTEC.r_path, post
            )
            if(response && response.data){
                const res = response.data.results;
                localStorage.setItem('allProducts',JSON.stringify(res))
            }
        },
        getPaymentMethod(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]',1);
            let x = [];
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    // // console.log(res);
                    for(let i = 0;i<res.results.data.length;i++){
                        if(i == 0){
                            this.paytype = res.results.data[i].id;
                        }
                        x.push({
                            text: this.$cookies.get(this.$store.state.LangCooki) == "ar" ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                            value: res.results.data[i].id
                        })
                    }
                    this.paytypes = x;
                    // this.paytypes.push(
                    //     {
                    //         text: this.lang.multi_payment,
                    //         value: -1
                    //     }
                    // )
                }
            )
        },
        cancelOrder(){
            this.subcats = []
            this.itemrows =  []
            this.allitems =  []
            this.customer = {
                id: '',
                mobile: '',
                full_name: '',
                customerid: '',
            }
            this.company = {
                fullname: '',
                vatid: '',
            }
            this.discount =  0
            this.notes = '';
            this.paytype = 1;
            this.invtype = 1;
            this.paidamount = 0;
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.mobile = res.results.data['mobile'];
                        this.customer.full_name = res.results.data['full_name'];
                        this.customer.customerid = res.results.data['customerid'];
                        this.company.fullname = res.results.data['company_name'];
                        this.company.vatid = res.results.data['company_vatid'];
                    }
                    
                }
            )
        },
        updatePaidAmount(){
            // // console.log();

            
            this.$refs.addInvoice.cash = this.inftotal;
            this.$refs.addInvoice.span = 0;
            this.paidamount = this.inftotal;
        },
        addItem(item,t){
            let check = false;
            let cart = [];
            // // console.log("items",item)
            for(let i=0;i<this.itemrows.length;i++){
                
                if(this.itemrows[i].id == item.id){
                    this.itemrows[i].qty = parseFloat(this.itemrows[i].qty) + 1;
                    check=true;
                }
                cart.push(this.itemrows[i]);
            }
            // // console.log("cart" , cart)
            this.itemrows = cart;
            // for(let i=0;i<this.itemrows.length;i++){
            //     if(this.itemrows[i].id == item.id){
            //         this.itemrows[i].qty = parseFloat(this.itemrows[i].qty) + parseFloat(1);
            //         this.itemrows[i].ftotal = this.$RoundNum(parseFloat(this.itemrows[i].qty) * parseFloat(item.ftotal),2);
            //         this.itemrows[i].total = this.$RoundNum(parseFloat(this.itemrows[i].qty) * parseFloat(item.sprice),2);
            //         this.itemrows[i].vat = this.$RoundNum(parseFloat(this.itemrows[i].qty) * parseFloat(item.svat),2);
            //         check = true;
            //     }
            // }
            if(!check){
                this.itemrows.push(item);
                this.paidamount = this.inftotal;
            }
            // item.qty = 1;
            // // console.log(item.catid);
            // if(t)
            //     this.getProducts(item.catid)
        },
        selectThie(item,index){
           if(this.selectedIndex != -1){
               if(this.selectedIndex != index){
                   document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                   this.selectedIndex = index;
                    this.selectedItem = item;
                    document.getElementById('item_'+index).style.background = '#ccc';
                    return false;
               }
               this.selectedIndex = -1;
               this.selectedItem = {};
               document.getElementById('item_'+index).style.background = '#FFF';
           }else{
                this.selectedIndex = index;
                this.selectedItem = item;
                document.getElementById('item_'+index).style.background = '#ccc';
               
           }
        },
        delSel(){
            if(this.selectedIndex != -1){
                this.itemrows.splice(this.selectedIndex,1);
                document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                this.selectedIndex = -1;
                this.selectedItem = {};
            }
        },
        addThis(n){
            if(this.selectedIndex != -1){
                this.itemrows[this.selectedIndex].qty = n;
                // this.itemrows[this.selectedIndex].total = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice));
                // this.itemrows[this.selectedIndex].vat = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat));
                // this.itemrows[this.selectedIndex].ftotal = this.$RoundNum(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat));
                document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                this.selectedIndex = -1;
                this.selectedItem = {};
            }
        },
        addOne(){
            if(this.selectedIndex != -1){
                let n = +this.itemrows[this.selectedIndex].qty + 1;
                this.itemrows[this.selectedIndex].qty = n;
                // this.itemrows[this.selectedIndex].total = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice));
                // this.itemrows[this.selectedIndex].vat = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat));
                // this.itemrows[this.selectedIndex].ftotal = this.$RoundNum(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat));                
            }
        },
        minOne(){
            if(this.selectedIndex != -1 && this.itemrows[this.selectedIndex].qty > 1){
                let n = +this.itemrows[this.selectedIndex].qty - 1;
                this.itemrows[this.selectedIndex].qty = n;
                // this.itemrows[this.selectedIndex].total = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice));
                // this.itemrows[this.selectedIndex].vat = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat));
                // this.itemrows[this.selectedIndex].ftotal = this.$RoundNum(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat));
            }
        },
        getCats(item){
            if(item.type != 0 && item.id != 0){
                this.getProducts(item.id);
                return false;
            }
            const post = new FormData();
            post.append('type', 'getPCats');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',item.id);
            post.append('data[type]',item.type);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                if(item.id == 0){
                    this.categories = res.results.data;
                }else{
                    this.allitems = [];
                    this.subcats = res.results.data;
                }
            })
        },
        getProducts(catid){
            this.subcats = [];
            const post = new FormData();
            post.append('type', 'getCatProducts');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',catid);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                this.allitems = res.results.data;
            })
        },
        addInvoice(){
            if(this.itemrows.length == 0){
                this.$snotify.error(this.lang.you_can_not_add_empty_invoice, this.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            
            const post = new FormData();
            post.append("type" , "addPOSInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));

            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.itemrows.length;i++){
                for(const [key, value] of Object.entries(this.itemrows[i])){
                    post.append('data[rows]['+i+']['+key+']]',value);
                }
            }
            
            post.append("data[discount]",this.discount);
            post.append("data[notes]",this.notes);
            post.append("data[paytype]",this.paytype);
            post.append("data[invtype]",this.invtype);
            post.append("data[paidamount]",this.paidamount);
            post.append("data[intotal]",this.intotal);
            post.append("data[invat]",this.invat);
            post.append("data[inftotal]",this.inftotal);
            post.append("data[cash]",this.cash);
            post.append("data[cash]",this.cash);
            post.append("data[discount_type]",this.discount_type);
            post.append("data[total_after_discount]",this.total_after_discount);
            axios.post(
                this.$store.state.SAMCOTEC.r_path,  post
            ).then((response) => {
                const res = response.data;
                let message = "تمت اضافة الفاتورة بنجاح";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: "الغاء", 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$refs.addInvoice.cash = 0;
                this.$refs.addInvoice.span = 0;
                this.discount_type = 1;
                this.$refs.addInvoice.$refs.adivncloseit.click()
                this.printPDF(res.results.data);
                this.cancelOrder();
            })
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print-80m.php?invid='+id;
        },
        openGet(){
            if(this.remaintotal < 0) return false;
            const post = new FormData();
            post.append("type", "getSheft");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',1)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                if(response.data.results.data.userid != 0){
                    if(response.data.results.data.userid != response.data.results.myid){
                        // this.errorMessage = 'هناك وردية بدأت العمل من قبل موظف آخر .. يرجى مراجعة الموظف واغلاق الورديه قبل تسجيل الدخول';
                    }else{
                        this.showMessage = false;
                    }
                }else{
                    this.shiftOpen = false;
                }
            })
        }
    },
    created() {
        this.openShift();
        this.openGet();
        this.getCats({id:0,type:0});
        this.getPaymentMethod();
        // this.getAllItems();
    },
}
</script>

<style>
.updateButton{
    background:#0d6efd;
    color: #fff;
    padding: 5px;
    margin: auto;
    width:45%;
    margin-inline-end: 10px;
}
.cancelButton{
    background:red !important;
    color: #fff;
    padding: 5px;
    margin: auto;
    width:45%;
}
.editPrice{
    display:none;
    position:fixed;
    top:200px;
    left: calc(50% - 135px);
    width:270px;
    background: #fff;
    border:1px solid #000;
    z-index: 10000;
}
.editPrice .title {
    font-size:0.9rem !important;
    font-family: 'Cairo' !important;
    font-weight: 500;
    text-align:center;
    background:#000;
    color: #fff;
    border-bottom:1px solid #000;
}
.editPrice .body {
    font-size:0.9rem !important;
    padding: 3px;
}
.editPrice .buttons {
    font-size:0.9rem !important;
    padding: 3px;
    background: #ccc;
    text-align: center;
    border: none;
}
.scrollMe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    text-align:center;
    left:2px;
    width:50px;
    top:130px;
    bottom:40px;
}
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
._invoicetables_{
    max-height:250px;
    min-height:250px;
    overflow: auto;
}
#countbtns{
    background:#fff;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
    display:flex;
    flex-wrap: nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
._mxmnheight_{
    max-height:calc(100vh - 70px);
    min-height:calc(100vh - 70px);
    position: relative;
}
._mxmnheight_-200{
    max-height:calc(100vh - 250px);
    overflow: auto;
}
.wp-100{
    width: 145px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:130px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#fff;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    /* background:#222;
    color:#fff; */
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
}
.btn-gray{
    background:#fff;
    color: #000;
    border-radius: 7px;
}
.btn-wght{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    position: relative;
    padding-top:10px !important;

}
.btn-wght:active,.btn-wght:hover,.btn-wght:visited{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    
}
.btnorange,.btnorange:active,.btnorange:hover,.btnorange:visited{
    background: rgb(230, 122, 0) !important;
    color: #fff !important;
    font-weight: 800;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: darkolivegreen !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
.rowPrice{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    background:rgb(230, 122, 0);
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.rrowPrice{
    position: absolute;
    bottom:0;
    left:0;
    right: 0;
    background:black;
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.gradBG{
    background: rgb(31,43,153) !important;
background: linear-gradient(180deg, rgba(31,43,153,1) 0%, rgba(31,87,153,1) 3%, rgba(31,89,153,1) 18%, rgba(31,103,153,1) 50%, rgba(31,83,153,1) 82%, rgba(31,64,153,1) 97%, rgba(31,43,153,1) 100%) !important;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.border2black,.modal-content{
    border:2px solid #000 !important;
}
.positionEnd,.positionEnd:hover{
    background:transparent !important;
    border:none;
    box-shadow: none;
    color:#fff !important;
}
.flexit{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
}
.modal-header,.modal-footer{
    padding:5px 10px 0 0 !important;
    background:black !important ;
}
.modal-footer > * {
    margin:0;
}
.modal{
    top:40px;
}
.catBTN{
    width:145px !important;
}
.displayFlexNo{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items:center;
}
._actionBTNs_{
    border:2px solid #fff !important;
    width:118px !important;
    font-size:.75rem !important;
    color:#fff;
    margin-inline-end: 5px;
    font-size:.8rem;
}
.qtyBTN{
    width:40px;
    margin-inline-start:5px;
    font-size:.7rem;
}
.actBTN{
    width:45%;
    margin-inline-start:5px;
    margin-bottom:5px;
}
.w100{
    width:100px;
}
.moneyTables{
    position:absolute;
    top:385px;
    left:0px;
    right:5px;
    text-align:center;
}
@media screen and (max-device-width: 1400px)
              and (min-device-width: 480px)  {
    .itemrowstd{
        font-size:.8rem !important;
        height:30px !important
    }
    .w100{
        font-size: .6rem !important;
        width:80px;
    }
    .actBTN{
        font-size:.7rem
    }
    *{
        font-size:0.645rem;
    }
    .catBTN{
        width:95px !important;
        font-size:0.645rem;
    }
    ._actionBTNs_{
        margin-top:10px;
        width:115px !important;
        font-size:0.5rem !important;
        margin-inline-end:5px
    }
    /* ._invoicetable_{
        max-height:200px;
        min-height:200px;
        overflow: auto;
    } */
    .moneyTable{
        top:260px;
    }
    
}
.floatingButtom,.floatingButtom:hover,.floatingButtom:active{
    position:fixed;
    bottom:30px;
    left:30px;
    width:150px;
    /* writing-mode: vertical-rl; */
    background:black;
    color:#fff;
    cursor:pointer;
    padding:5px;
    text-align:center;
}
.brightness { filter: brightness(0.25); }
</style>